<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div v-if="!getMenu">
        <div class="mx-auto sm:max-w-xl md:max-w-full xl:max-w-screen-xl p-2">
          <div class="mt-24">
            <div
              class="grid gap-4 grid-cols-2 lg:grid-cols-4 md:grid-cols-2 mt-10"
            >
              <div
                v-for="caseEx in glassExample.glassExample"
                :key="caseEx"
                class="cursor-pointer hover:bg-gray-200 p-3 rounded"
              >
                <div
                  class="transition duration-300 ease-in-out transform hover:-translate-y-1 cursor-pointer rounded-sm rounded"
                >
                  <img
                    :src="caseEx.img"
                    alt="Image 1"
                    class="inset-0 object-cover ease-in-out opacity-100 hover:opacity-0 w-full"
                  />

                  <img
                    :src="caseEx.hoverImg"
                    alt="Image 2"
                    class="absolute inset-0 object-cover transition duration-500 ease-in-out opacity-0 hover:opacity-100 w-full"
                  />
                </div>

                <!-- <img class="w-full" /> -->
                <div class="text-center">
                  <h3 class="lg:text-lg text-lg pt-3">
                    {{ caseEx.name }}
                    <br />
                    <br />
                    <b class="text-xl font-sans font-extrabold">
                      THB {{ caseEx.price }}
                    </b>
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-24">
            <div
              class="grid gap-4 grid-cols-2 lg:grid-cols-4 md:grid-cols-2 mt-10"
            >
              <div
                v-for="caseEx in umbrellaExample.umbrellaExample"
                :key="caseEx"
                class="cursor-pointer hover:bg-gray-200 p-3 rounded"
              >
                <img class="w-full" :src="caseEx.img" />
                <div class="text-center">
                  <h3 class="lg:text-lg text-lg pt-3">
                    {{ caseEx.name }}
                    <br />
                    <br />
                    <b class="text-xl font-sans font-extrabold">
                      THB {{ caseEx.price }}
                    </b>
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-24">
            <div
              class="grid gap-4 grid-cols-2 lg:grid-cols-4 md:grid-cols-2 mt-10"
            >
              <div
                v-for="caseEx in caseExample.caseExample"
                :key="caseEx"
                class="cursor-pointer hover:bg-gray-200 p-3 rounded"
              >
                <a :href="'/products_detail/' + caseEx._id">
                  <img class="w-full" :src="caseEx.img" />
                  <div class="text-center">
                    <h3 class="lg:text-lg text-lg pt-3">
                      {{ caseEx.name }}
                      <br />
                      {{ caseEx.color }}
                      <br /><br />
                      <b class="text-xl font-sans font-extrabold">
                        THB {{ caseEx.price }}
                      </b>
                    </h3>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div class="mt-24">
            <div
              class="grid gap-4 grid-cols-2 lg:grid-cols-4 md:grid-cols-2 mt-10"
            >
              <div
                v-for="caseEx in glassExample.glassExample"
                :key="caseEx"
                class="cursor-pointer hover:bg-gray-200 p-3 rounded"
              >
                <img class="w-full" :src="caseEx.img" />
                <div class="text-center">
                  <h3 class="lg:text-lg text-lg pt-3">
                    {{ caseEx.name }}
                    <br />
                    <br />
                    <b class="text-xl font-sans font-extrabold">
                      THB {{ caseEx.price }}
                    </b>
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-24">
            <div
              class="grid gap-4 grid-cols-2 lg:grid-cols-4 md:grid-cols-2 mt-10"
            >
              <div
                v-for="caseEx in umbrellaExample.umbrellaExample"
                :key="caseEx"
                class="cursor-pointer hover:bg-gray-200 p-3 rounded"
              >
                <img class="w-full" :src="caseEx.img" />
                <div class="text-center">
                  <h3 class="lg:text-lg text-lg pt-3">
                    {{ caseEx.name }}
                    <br />
                    <br />
                    <b class="text-xl font-sans font-extrabold">
                      THB {{ caseEx.price }}
                    </b>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <StickFooter class="mt-44" />
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/Footer.vue";

import { mapGetters, mapActions } from "vuex";

import caseExample from "../../caseExample";
import glassExample from "../../glassExample";
import umbrellaExample from "../../umbrellaExample";

import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";

// import { products, main_product, products_2 } from "../../products";

export default {
  data() {
    return {
      caseExample: caseExample,
      glassExample: glassExample,
      umbrellaExample: umbrellaExample,
    };
  },
  components: {
    ConnectWallet,
    StickFooter,
    Carousel,
    Navigation,
    Pagination,
    Slide,
  },
  computed: mapGetters(["getMenu"]),

  methods: {},
  created() {},
};
</script>
