module.exports = {
  caseExample: [
    {
      img: "https://cdn-stamplib.casetify.com/cms/image/49d5e684a437e125240f02584d04a2b1.png",
      name: "iPhone 14 Pro Bounce Extreme Case MagSafe Compatible Waterproof",
      color: "Black",
      price: "599",
      _id: "1",
    },
    {
      img: "https://cdn-image02.casetify.com/usr/10635/30280635/~v31/28187940_iphone-14-pro__color_white_16004813.png.350x350-w.m80.jpg",
      name: "iPhone 14 Pro Bounce Extreme Case MagSafe Compatible Waterproof",
      color: "Black",
      price: "1,900",
      _id: "2",
    },
    {
      img: "https://cdn-image02.casetify.com/usr/8405/29948405/~v71/27729581_iphone-14-pro__color_white_16005832.png.350x350-w.m80.jpg",
      name: "iPhone 14 Pro Bounce Extreme Case MagSafe Compatible Waterproof",
      color: "Black",
      price: "1,900",
      _id: "3",
    },
    {
      img: "https://cdn-image02.casetify.com/usr/14315/17384315/~v21/28785452_iphone-14-pro__color_white_16004898.png.350x350-w.m80.jpg",
      name: "iPhone 14 Pro Bounce Extreme Case MagSafe Compatible Waterproof",
      color: "Black",
      price: "1,900",
      _id: "4",
    },
  ],
};
